import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import ConnectWallet from '../Wallet/ConnectWallet'; 
import { ChevronDown, Construction } from 'lucide-react'

const blockchains = [
  { id: 'op_net', name: 'OP_NET', logo: 'https://raw.githubusercontent.com/KittySwap-DEX/images/72bb8a822602deb3249a17fea9e48d03282a1380/opnet.png' }
]

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedChain, setSelectedChain] = useState(blockchains[0])

  return (
    <header className="bg-gradient-to-r from-[#100C1C] to-[#1A1528] text-white py-4 px-6">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-8">
          <img src="./images/logo.png?height=40&width=120" alt="Logo" className="h-10" />
          <nav className="hidden md:flex space-x-6">
            <NavLink to="/home" className={({ isActive }) => 
              `text-sm font-medium transition-colors hover:text-[#F89D30] ${isActive ? 'text-[#F89D30]' : 'text-white'}`
            }>
              HOME
            </NavLink>
            <NavLink to="/swap" className={({ isActive }) => 
              `text-sm font-medium transition-colors hover:text-[#F89D30] ${isActive ? 'text-[#F89D30]' : 'text-white'}`
            }>
              SWAP
            </NavLink>
            <div className="flex flex-col items-center relative">
              <NavLink to="/prediction" className={({ isActive }) => 
                `text-sm font-medium transition-colors hover:text-[#F89D30] ${isActive ? 'text-[#F89D30]' : 'text-white'}`
              }>
                PREDICTION
              </NavLink>
              <div className="flex items-center absolute -bottom-6 left-1/2 transform -translate-x-1/2 bg-[#F89D30] text-[#100C1C] text-[10px] font-bold px-1.5 py-0.5 rounded-full animate-pulse">
                <Construction className="w-3 h-3 mr-1" />
                <span>Building</span>
              </div>
            </div>
           { /*  <NavLink to="/stake" className={({ isActive }) => 
              `text-sm font-medium transition-colors hover:text-[#F89D30] ${isActive ? 'text-[#F89D30]' : 'text-white'}`
            }>
              STAKE
            </NavLink>
            <NavLink to="/perps" className={({ isActive }) => 
              `text-sm font-medium transition-colors hover:text-[#F89D30] ${isActive ? 'text-[#F89D30]' : 'text-white'}`
            }>
              PERPS
            </NavLink> */}
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center space-x-2 bg-[#2A2438] hover:bg-[#3A3448] text-white px-3 py-2 rounded-md transition-colors"
            >
              <img src={selectedChain.logo} alt={`${selectedChain.name} logo`} className="w-6 h-6 mr-2" />
              <span>{selectedChain.name}</span>
              <ChevronDown className="h-4 w-4" />
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#2A2438] ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {blockchains.map((chain) => (
                    <button
                      key={chain.id}
                      onClick={() => {
                        setSelectedChain(chain)
                        setIsOpen(false)
                      }}
                      className="flex items-center w-full text-left px-4 py-2 text-sm text-white hover:bg-[#3A3448] transition-colors"
                      role="menuitem"
                    >
                      <img src={chain.logo} alt={`${chain.name} logo`} className="w-6 h-6 mr-2" />
                      {chain.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <ConnectWallet />
        </div>
      </div>
    </header>
  )
};

export default Header;