import { useState, useCallback } from 'react';
import { OPNetLimitedProvider, UnisatSigner } from '@btc-vision/transaction';
import { useWallet } from '../context/WalletContext';
import { useOP20Contract } from './useOP20Contract';
import { useRouterContract } from './useRouterContract';

export const useApproveSwap = (payToken: string, spenderAddress: string, receiveToken: string) => {
    const { provider } = useWallet();
    const tokenContract = useOP20Contract(payToken, 'regtest', provider);
    const routerContract = useRouterContract('regtest', provider);
    const [loading, setLoading] = useState<boolean>(false);

    const executeApprovalAndSwap = useCallback(async (amount: bigint, minReceiveAmount: bigint) => {
        if (!tokenContract || !routerContract) {
            console.error('Token contract or router contract not found.');
            return false;
        }

        setLoading(true);

        try {
            // Step 1: Approve tokens
            console.log('Initiating approval...');
            const approveTx = await tokenContract.approve(spenderAddress, amount);
            console.log('ApproveTx:', approveTx);

            if (!approveTx || !approveTx.result || !approveTx.result.transactionHash) {
                console.error('Approval transaction did not return a valid result.', approveTx);
                return false;
            }

            const transactionHash = approveTx.result.transactionHash;
            console.log('Transaction Hash:', transactionHash);

            // Step 2: Poll for the transaction receipt
            let receipt = null;
            while (!receipt) {
                console.log('Fetching transaction receipt...');
                try {
                    receipt = await provider.getTransactionReceipt(transactionHash);
                    if (receipt) {
                        console.log('Transaction Receipt:', receipt);
                        break; // Exit loop when receipt is found
                    }
                    console.log('Transaction receipt not found yet, retrying...');
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
                } catch (error) {
                    console.error('Error fetching transaction receipt:', error);
                    return false;  // Exit if fetching the receipt fails
                }
            }

            if (!receipt) {
                console.error('Approval transaction failed or was not confirmed.', receipt);
                return false;
            }

            console.log('Approval successful:', receipt);

            // Step 3: Execute the swap (if approval is successful)
            // (Implement your swap logic here)

            return true;
        } catch (error) {
            console.error('Approval and swap process failed:', error);
            return false;
        } finally {
            setLoading(false);
        }
    }, [provider, spenderAddress, tokenContract, routerContract]);

    return { executeApprovalAndSwap, loading };
};
