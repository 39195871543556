import React from 'react';
import StakeInterface from '../../components/StakeInterface/StakeInterface';

const Stake: React.FC = () => {
  return (
      <StakeInterface />
  );
};

export default Stake;
