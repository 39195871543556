import React from 'react';
import Module from '../../components/Module/Module';
import '../index.css';

const Trade: React.FC = () => {
  return (
      <Module />
  );
};

export default Trade;
