import { useMemo } from 'react';
import { getContract, JSONRpcProvider } from 'opnet';
import { MOTOSWAP_ROUTER_ABI } from 'opnet';
import { addresses } from '../config/constants/addresses';
import { Address } from '@btc-vision/bsi-binary';
import { BaseContractProperties } from 'opnet';

interface IMotoSwapRouterContract extends BaseContractProperties {
    getAmountsOut(amountIn: bigint, path: Address[]): Promise<{ amountsOut: bigint[] }>;
    getAmountsIn(amountOut: bigint, path: Address[]): Promise<{ amountsIn: bigint[] }>;
    swapExactTokensForTokensSupportingFeeOnTransferTokens(
        amountIn: bigint,
        amountOutMin: bigint,
        path: Address[],
        to: Address,
        deadline: bigint
    ): Promise<any>;
}


export const useRouterContract = (network: 'regtest' | 'fractal', provider: JSONRpcProvider | null) => {
    const routerAddress = addresses[network].router;

    return useMemo(() => {
        if (!provider) return null;
        return getContract<IMotoSwapRouterContract>(routerAddress, MOTOSWAP_ROUTER_ABI, provider);
    }, [routerAddress, provider]);
};