import React from 'react'
import { useWallet } from '../../context/WalletContext'

const ConnectWallet: React.FC = () => {
  const { address, balance, connectWallet, disconnectWallet } = useWallet()

  const formatBalance = (balance: string | number | null): string => {
    if (balance === null) return '0.0000'
    const numBalance = typeof balance === 'string' ? parseFloat(balance) : balance
    return numBalance.toFixed(4)
  }

  return (
    <button 
      onClick={address ? disconnectWallet : connectWallet} 
      className="flex items-center space-x-2 bg-[#F89D30] hover:bg-[#E08A1E] text-white px-4 py-2 rounded-full transition-colors text-sm font-medium"
    >
      {address ? (
        <>
          <svg className="h-5 w-5 text-white" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm7.189-17.98c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z"/>
          </svg>
          <span>{formatBalance(balance)}</span>
          <span className="bg-white/20 px-2 py-1 rounded-full">
            {address.substring(0, 4)}...{address.substring(address.length - 4)}
          </span>
        </>
      ) : (
        'Connect Wallet'
      )}
    </button>
  )
}

export default ConnectWallet