import { useMemo } from 'react';
import { getContract, BaseContractProperties } from 'opnet';
import { ChainId } from '@btc-vision/transaction';
import { OP_20_ABI } from 'opnet';

interface IOP20Contract extends BaseContractProperties {
    approve(spender: string, amount: bigint): Promise<any>;
    balanceOf(address: string): Promise<bigint>;
    transfer(to: string, amount: bigint): Promise<any>;
    allowance(owner: string, spender: string): Promise<bigint>;  // Add this method
}

export const useOP20Contract = (
    address: string,
    network: 'regtest' | 'fractal',
    provider: any
): IOP20Contract | null => {
    const abi = OP_20_ABI;
    const chainId = network === 'regtest' ? ChainId.Bitcoin : ChainId.Fractal;

    return useMemo(() => {
        if (!provider) return null;
        return getContract<IOP20Contract>(address, abi, provider, chainId.toString());
    }, [address, network, provider]);
};
