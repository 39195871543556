import React from 'react';
import PredictionInterface from '../../components/PredictionInterface/PredictionInterface';

const Stake: React.FC = () => {
  return (
      <PredictionInterface />
  );
};

export default Stake;
