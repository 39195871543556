import { useState, useCallback } from 'react';
import { networks } from 'bitcoinjs-lib';
import { OPNetLimitedProvider, TransactionFactory, UnisatSigner, FetchUTXOParamsMultiAddress } from '@btc-vision/transaction';
import { useWallet } from '../context/WalletContext';
import { useOP20Contract } from './useOP20Contract';
import { Buffer } from 'buffer';
import { useRouterContract } from './useRouterContract';

export const useApproval = (payToken: string, spenderAddress: string) => {
    const { address, provider } = useWallet();
    const routerContract = useRouterContract('regtest', provider);
    const tokenContract = useOP20Contract(payToken, 'regtest', provider);
    const [loading, setLoading] = useState<boolean>(false);

    const checkAndApprove = useCallback(async (amount: bigint) => {
      if (!tokenContract) {
        console.error('Le contrat de token est introuvable.');
        return false;
      }

      setLoading(true);

      try {
        // Effectuer systématiquement l'approbation
        console.log('Lancement de l\'approbation systématique...');
        
        // 1. Approve Transaction
        const approveTx = await tokenContract.approve(spenderAddress, amount);
        console.log('ApproveTx:', approveTx);

        if (!approveTx.result || !approveTx.properties?.success) {
          console.error('L\'approbation a échoué ou n\'a pas été réussie.');
          return false;
        }

        // 2. Récupérer les UTXOs
        const utxoManager = new OPNetLimitedProvider(process.env.REACT_APP_RPC_URL!);
        const utxoSetting: FetchUTXOParamsMultiAddress = {
          addresses: [address!],
          minAmount: BigInt(10000), // minAmount in Satoshis
          requestedAmount: amount + BigInt(50000), // Inclure une estimation des frais
        };
        const utxos = await utxoManager.fetchUTXOMultiAddr(utxoSetting);
        console.log('UTXOs:', utxos);

        if (!utxos || !utxos.length) {
          throw new Error('Aucun UTXO trouvé');
        }

        const totalUTXOValue = utxos.reduce((total, utxo) => total + BigInt(utxo.value), BigInt(0));
        const estimatedFee = BigInt(50000); // Estimation des frais
        
        if (totalUTXOValue < amount + estimatedFee) {
          throw new Error(`Fonds insuffisants: Total UTXO Value: ${totalUTXOValue}, Amount + Fee: ${amount + estimatedFee}`);
        }

        // 3. Signer l'approbation avec les interactions nécessaires
        const factory = new TransactionFactory();
        const keypair = new UnisatSigner();
        await keypair.init();

        const interactionParameters = {
          from: keypair.p2tr,
          to: spenderAddress,
          utxos: utxos,
          network: networks.regtest,
          feeRate: 450,
          priorityFee: BigInt(10000),
          calldata: Buffer.from(approveTx.calldata), // Convertir en Buffer si nécessaire
          signer: keypair,
        };

        console.log('Approval Interaction Parameters:', interactionParameters);
        const signedTx = await factory.signInteraction(interactionParameters);
        console.log('Signed Transaction:', signedTx);

        if (!signedTx) {
          throw new Error('Échec de la signature de la transaction');
        }

        // 4. Diffuser la transaction
        const broadcastTxA = await provider.sendRawTransaction(signedTx[0], false);
        const broadcastTxB = await provider.sendRawTransaction(signedTx[1], false);

        console.log('BroadcastTxA:', broadcastTxA);
        console.log('BroadcastTxB:', broadcastTxB);

        if (!broadcastTxA.result || !broadcastTxB.result) {
          throw new Error('Échec de la diffusion de la transaction');
        }

        console.log('Transaction approuvée et diffusée avec succès:', broadcastTxA.result);
        
        return true;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Approval failed:', error.message);
        } else {
          console.error('Approval failed:', error);
        }
        return false;
      } finally {
        setLoading(false);
      }
    }, [address, provider, spenderAddress, tokenContract]);

    return { checkAndApprove, loading };
};
