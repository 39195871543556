'use client'

import React, { useState, useEffect } from 'react';
import { Clock, Trophy, Plus, Minus, Coins, Box, ArrowUp, ArrowDown, Check } from 'lucide-react';

interface Topic {
  id: number;
  topic: string;
  createdAt: number;
  totalBlocks: number;
  totalUpBets: number;
  totalDownBets: number;
  fixedBetAmount: string;
  isFinished: boolean;
}

const initialTopics: Topic[] = [
  { id: 1, topic: "Will BTC reach $70k this week?", createdAt: 800000, totalBlocks: 100, totalUpBets: 50, totalDownBets: 50, fixedBetAmount: "0.001", isFinished: false },
  { id: 2, topic: "Will MOTO reach $7 this month?", createdAt: 799900, totalBlocks: 200, totalUpBets: 40, totalDownBets: 40, fixedBetAmount: "0.002", isFinished: false },
  { id: 3, topic: "Will PEPE hit $1 by end of year?", createdAt: 799950, totalBlocks: 150, totalUpBets: 60, totalDownBets: 60, fixedBetAmount: "0.0005", isFinished: false },
  { id: 4, topic: "Did ETH surpass $5k in Q2 2024?", createdAt: 790000, totalBlocks: 100, totalUpBets: 75, totalDownBets: 25, fixedBetAmount: "0.001", isFinished: true },
];

export default function PredictionFeature() {
  const [topics, setTopics] = useState<Topic[]>(initialTopics);
  const [newTopic, setNewTopic] = useState("");
  const [totalBlocks, setTotalBlocks] = useState(100);
  const [fixedBetAmount, setFixedBetAmount] = useState("0.001");
  const [isCreateTopicOpen, setIsCreateTopicOpen] = useState(false);
  const [userBets, setUserBets] = useState<{[key: number]: 'up' | 'down' | null}>({});
  const [currentBlockNumber, setCurrentBlockNumber] = useState(800000);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [activeTab, setActiveTab] = useState<'active' | 'finished'>('active');

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime(prev => prev + 1);
      setCurrentBlockNumber(prev => prev + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleCreateTopic = () => {
    if (newTopic && totalBlocks >= 50 && totalBlocks <= 500 && fixedBetAmount) {
      const newTopicObj: Topic = {
        id: topics.length + 1,
        topic: newTopic,
        createdAt: currentBlockNumber,
        totalBlocks: totalBlocks,
        totalUpBets: 0,
        totalDownBets: 0,
        fixedBetAmount: fixedBetAmount,
        isFinished: false,
      };
      setTopics([newTopicObj, ...topics]);

      setNewTopic("");
      setTotalBlocks(100);
      setFixedBetAmount("0.001");
      setIsCreateTopicOpen(false);
    }
  };

  const handlePlaceBet = (topicId: number, isUpBet: boolean) => {
    if (userBets[topicId] === undefined) {
      setUserBets(prev => ({...prev, [topicId]: isUpBet ? 'up' : 'down'}));
      setTopics(prevTopics => 
        prevTopics.map(topic => 
          topic.id === topicId 
            ? {
                ...topic, 
                totalUpBets: isUpBet ? topic.totalUpBets + 1 : topic.totalUpBets,
                totalDownBets: !isUpBet ? topic.totalDownBets + 1 : topic.totalDownBets
              } 
            : topic
        )
      );
    }
  };

  const handleClaim = (topicId: number) => {
    // Implement claim logic here
    console.log(`Claiming for topic ${topicId}`);
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getBlockProgress = (topic: Topic) => {
    const elapsedBlocks = currentBlockNumber - topic.createdAt;
    const progress = (elapsedBlocks / topic.totalBlocks) * 100;
    return Math.min(100, Math.max(0, progress));
  };

  const getRemainingTime = (topic: Topic) => {
    const elapsedBlocks = currentBlockNumber - topic.createdAt;
    const remainingBlocks = Math.max(0, topic.totalBlocks - elapsedBlocks);
    return remainingBlocks * 10 * 60;
  };

  const sortedTopics = topics
    .filter(topic => activeTab === 'active' ? !topic.isFinished : topic.isFinished)
    .sort((a, b) => (b.totalUpBets + b.totalDownBets) - (a.totalUpBets + a.totalDownBets));

  const noSpinnerStyles = `
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }`;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#100C1C] to-[#1A1528] flex items-start justify-center py-6 px-4">
      <style>{noSpinnerStyles}</style>
      <div className="bg-[#181c2c] rounded-xl shadow-2xl p-4 w-full max-w-2xl">
        <h1 className="text-2xl font-bold text-white mb-6 text-center">Prediction Market</h1>
        
        <div className="bg-[#2c2f36] rounded-lg p-4 mb-6 border border-[#f89d30]">
          <div className="flex mb-4">
            <button
              onClick={() => setActiveTab('active')}
              className={`flex-1 py-2 px-4 rounded-tl-lg rounded-bl-lg ${activeTab === 'active' ? 'bg-[#f89d30] text-white' : 'bg-[#3a3f50] text-gray-300'} transition-colors`}
            >
              Active Topics
            </button>
            <button
              onClick={() => setActiveTab('finished')}
              className={`flex-1 py-2 px-4 rounded-tr-lg rounded-br-lg ${activeTab === 'finished' ? 'bg-[#f89d30] text-white' : 'bg-[#3a3f50] text-gray-300'} transition-colors`}
            >
              Finished Topics
            </button>
          </div>
          <div className="max-h-96 overflow-y-auto pr-2" style={{ scrollbarWidth: 'thin', scrollbarColor: '#f89d30 #2c2f36' }}>
            {sortedTopics.map((topic) => (
              <div key={topic.id} className="mb-4 p-4 bg-[#1a1d26] rounded-lg relative">
                {userBets[topic.id] && (
                  <div className={`absolute top-2 right-2 w-6 h-6 rounded-full flex items-center justify-center ${userBets[topic.id] === 'up' ? 'bg-green-500' : 'bg-red-500'}`}>
                    <Check className="w-4 h-4 text-white" />
                  </div>
                )}
                <h3 className="text-white font-medium mb-2 pr-8">{topic.topic}</h3>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center text-[#f89d30]">
                    <Coins className="w-4 h-4 mr-1" />
                    <span className="text-sm font-bold">Bet: {topic.fixedBetAmount} WBTC</span>
                  </div>
                  <div className="flex items-center text-[#f89d30]">
                    <Trophy className="w-4 h-4 mr-1" />
                    <span className="text-sm font-bold">
                      Pool: {((topic.totalUpBets + topic.totalDownBets) * parseFloat(topic.fixedBetAmount)).toFixed(4)} WBTC
                    </span>
                  </div>
                </div>
                {!topic.isFinished && (
                  <div className="flex items-center mb-3">
                    <div className="flex-grow bg-[#3a3f50] h-2 rounded-full">
                      <div
                        className="bg-[#f89d30] h-2 rounded-full"
                        style={{ width: `${getBlockProgress(topic)}%` }}
                      ></div>
                    </div>
                    <Box className="w-4 h-4 ml-2 mr-1 text-gray-400" />
                    <span className="text-gray-400 text-sm mr-2">{topic.totalBlocks}</span>
                    <Clock className="w-4 h-4 mr-1 text-gray-400" />
                    <span className="text-gray-400 text-sm">
                      {formatTime(getRemainingTime(topic))}
                    </span>
                  </div>
                )}
                <div className="flex space-x-2">
                  {topic.isFinished ? (
                    <button
                      onClick={() => handleClaim(topic.id)}
                      className="w-full py-2 px-4 rounded-lg bg-[#f89d30] text-white hover:bg-[#ffa94d] transition-colors flex items-center justify-center"
                    >
                      CLAIM
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => handlePlaceBet(topic.id, true)}
                        className={`flex-1 py-2 px-4 rounded-lg ${userBets[topic.id] === 'up' ? 'bg-green-600' : 'bg-green-500'} text-white hover:bg-green-600 transition-colors flex items-center justify-center ${userBets[topic.id] !== undefined ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={userBets[topic.id] !== undefined}
                      >
                        <ArrowUp className="w-4 h-4 mr-2" />
                        UP ({topic.totalUpBets})
                      </button>
                      <button
                        onClick={() => handlePlaceBet(topic.id, false)}
                        className={`flex-1 py-2 px-4 rounded-lg ${userBets[topic.id] === 'down' ? 'bg-red-600' : 'bg-red-500'} text-white hover:bg-red-600 transition-colors flex items-center justify-center ${userBets[topic.id] !== undefined ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={userBets[topic.id] !== undefined}
                      >
                        <ArrowDown className="w-4 h-4 mr-2" />
                        DOWN ({topic.totalDownBets})
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-[#2c2f36] rounded-lg overflow-hidden">
          <button
            onClick={() => setIsCreateTopicOpen(!isCreateTopicOpen)}
            className="w-full bg-[#3a3f50] hover:bg-[#4a4f60] text-white font-bold py-3 px-4 transition-colors flex items-center justify-center"
          >
            {isCreateTopicOpen ? <Minus className="w-5 h-5 mr-2" /> : <Plus className="w-5 h-5 mr-2" />}
            Create New Topic
          </button>
          {isCreateTopicOpen && (
            <div className="p-4">
              <input
                type="text"
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
                placeholder="Enter prediction topic"
                className="w-full bg-[#1a1d26] text-white text-lg py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f89d30] mb-3"
              />
              <input
                type="number"
                value={totalBlocks}
                onChange={(e) => setTotalBlocks(Math.max(50, Math.min(500, parseInt(e.target.value))))}
                placeholder="Total blocks (50-500)"
                min="50"
                max="500"
                className="w-full bg-[#1a1d26] text-white text-lg py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f89d30] mb-3"
              />
              <input
                type="number"
                value={fixedBetAmount}
                onChange={(e) => setFixedBetAmount(e.target.value)}
                placeholder="Fixed bet amount (WBTC)"
                step="0.0001"
                min="0.0001"
                className="w-full bg-[#1a1d26] text-white text-lg py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f89d30] mb-3"
              />
              <button
                onClick={handleCreateTopic}
                className="w-full bg-[#f89d30] hover:bg-[#ffa94d] text-white font-bold py-2 px-4 rounded-lg transition-colors"
              >
                Submit New Topic
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}