import React, { useState, useEffect } from 'react'

const Footer: React.FC = () => {
  const [btcPrice, setBtcPrice] = useState<string | null>(null)
  const [blockNumber, setBlockNumber] = useState<number | null>(null)

  useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd')
        const data = await response.json()
        setBtcPrice(data.bitcoin.usd.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
      } catch (error) {
        console.error('Failed to fetch BTC price:', error)
        setBtcPrice('N/A')
      }
    }

    const fetchBlockNumber = async () => {
      try {
        const response = await fetch('https://blockchain.info/q/getblockcount')
        const data = await response.text()
        setBlockNumber(parseInt(data, 10))
      } catch (error) {
        console.error('Failed to fetch block number:', error)
        setBlockNumber(null)
      }
    }

    fetchBtcPrice()
    fetchBlockNumber()

    const interval = setInterval(() => {
      fetchBtcPrice()
      fetchBlockNumber()
    }, 60000) // Update every minute

    return () => clearInterval(interval)
  }, [])

  return (
    <footer className="bg-gradient-to-r from-[#100C1C] to-[#1A1528] text-white py-4 px-4 md:px-8">
  <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
    <div className="mb-4 md:mb-0">
      <p className="text-sm text-gray-400">
        &copy; {new Date().getFullYear()} KittySwap. All rights reserved.
      </p>
    </div>
    <div className="flex space-x-6 mb-4 md:mb-0">
      <a href="https://x.com/kittyswapxyz" target="_blank" rel="noopener noreferrer" className="hover:text-[#F89D30] transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
          <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
        </svg>
        <span className="sr-only">Twitter</span>
      </a>
      <a href="https://t.me/kittyswapBTC" target="_blank" rel="noopener noreferrer" className="hover:text-[#F89D30] transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
          <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z"/>
        </svg>
        <span className="sr-only">Telegram</span>
      </a>
      <a href="https://github.com/KittySwap-DEX" target="_blank" rel="noopener noreferrer" className="hover:text-[#F89D30] transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
        </svg>
        <span className="sr-only">GitHub</span>
      </a>
    </div>
    <div className="flex items-center space-x-4">
      <div className="flex items-center">
        <svg className="h-5 w-5 mr-1 text-[#F7931A]" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm7.189-17.98c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z"/>
        </svg>
        <span className="text-sm">{btcPrice || 'Loading...'}</span>
      </div>
      <div className="flex items-center">
        <svg className="h-5 w-5 mr-1 text-[#F89D30]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4h4v4H4V4z" fill="currentColor" />
          <path d="M10 4h4v4h-4V4z" fill="currentColor" />
          <path d="M16 4h4v4h-4V4z" fill="currentColor" />
          <path d="M4 10h4v4H4v-4z" fill="currentColor" />
          <path d="M10 10h4v4h-4v-4z" fill="currentColor" />
          <path d="M16 10h4v4h-4v-4z" fill="currentColor" />
          <path d="M4 16h4v4H4v-4z" fill="currentColor" />
          <path d="M10 16h4v4h-4v-4z" fill="currentColor" />
          <path d="M16 16h4v4h-4v-4z" fill="currentColor" />
        </svg>
        <span className="text-sm">
          {blockNumber !== null 
            ? new Intl.NumberFormat('en-US', { 
                style: 'decimal',
                useGrouping: true,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(blockNumber)
            : 'Loading...'}
        </span>
      </div>
    </div>
  </div>
</footer>
  )
}

export default Footer