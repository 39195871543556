import React, { useState, useCallback, useEffect } from 'react';
import TokenModal from '../TokenModal/TokenModal';
import { useWallet } from '../../context/WalletContext';
import { WBTC_ABI, OP_20_ABI, getContract } from 'opnet';
import { useRouterContract } from '../../hooks/useRouterContract';
import tokensData from '../../config/constants/tokens.json';
import TabInterface from '../TabInterface/TabInterface';
import { useSwap } from '../../hooks/useSwap';
import { useApproval } from '../../hooks/useApproval';
import { addresses } from '../../config/constants/addresses';
import { useApproveSwap } from '../../hooks/useApproveSwap';

interface Token {
  address: string;
  symbol: string;
  image: string;
}

const Module: React.FC = () => {
  const { address, provider, fetchTokenBalance } = useWallet();
  const [payToken, setPayToken] = useState<Token | undefined>(undefined);
  const [receiveToken, setReceiveToken] = useState<Token | undefined>(undefined);

  const [payAmount, setPayAmount] = useState<string>('0');
  const [payTokenBalance, setPayTokenBalance] = useState<number>(0);
  const [receiveTokenBalance, setReceiveTokenBalance] = useState<number>(0);
  const [receiveAmount, setReceiveAmount] = useState<string>('0');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = useState<'pay' | 'receive'>('pay');
  const [liquidityTokenA, setLiquidityTokenA] = useState<number>(0);
  const [liquidityTokenB, setLiquidityTokenB] = useState<number>(0);
  const [poolInfo, setPoolInfo] = useState<any>(null);
  const [tokenPriceUSD, setTokenPriceUSD] = useState<number | null>(null);
  const routerContract = useRouterContract('regtest', provider);
  const routerAddress = addresses.regtest.router;
  const { executeApprovalAndSwap, loading: approvalSwapLoading } = useApproveSwap(payToken?.address || '', routerAddress, receiveToken?.address || '');

  const { checkAndApprove, loading: approvalLoading } = useApproval(payToken?.address || '', routerAddress);
  const { executeSwap, loading: swapLoading } = useSwap(payToken?.address || '', receiveToken?.address || '');

  const handleTokenSelect = useCallback(async (token: Token) => {
    const abi = token.symbol === 'WBTC' ? WBTC_ABI : OP_20_ABI;

    if (currentSelection === 'pay') {
      setPayToken(token);
      if (address) {
        const balance = await fetchTokenBalance(token.address, address, abi);
        setPayTokenBalance(balance ?? 0);
      }
    } else {
      setReceiveToken(token);
      if (address) {
        const balance = await fetchTokenBalance(token.address, address, abi);
        setReceiveTokenBalance(balance ?? 0);
      }
    }

    setIsModalOpen(false);

    if (payToken && receiveToken) {
      fetchLiquidityData(payToken.address, receiveToken.address);
    }

    fetchTokenPrice(token.address);
  }, [address, fetchTokenBalance, currentSelection, payToken, receiveToken]);

  const fetchLiquidityData = useCallback(async (tokenA: string, tokenB: string, retries = 3) => {
    try {
      const response = await fetch(`https://api.kittyswap.xyz/api/v1/liquidity?tokenA=${tokenA}&tokenB=${tokenB}`);
      const data = await response.json();

      if (data && data.totalTokenA !== undefined && data.totalTokenB !== undefined) {
        setPoolInfo(data);

        if (payToken?.address === tokenA && receiveToken?.address === tokenB) {
          setLiquidityTokenA(Number(data.totalTokenA));
          setLiquidityTokenB(Number(data.totalTokenB));
        } else if (payToken?.address === tokenB && receiveToken?.address === tokenA) {
          setLiquidityTokenA(Number(data.totalTokenB));
          setLiquidityTokenB(Number(data.totalTokenA));
        }
      } else if (retries > 0) {
        console.warn('Retrying fetchLiquidityData due to missing data...');
        fetchLiquidityData(tokenA, tokenB, retries - 1);
      } else {
        console.error('Failed to fetch liquidity data after retries');
      }
    } catch (error) {
      if (retries > 0) {
        console.warn('Retrying fetchLiquidityData due to error:', error);
        fetchLiquidityData(tokenA, tokenB, retries - 1);
      } else {
        console.error('Error fetching liquidity data after retries:', error);
      }
    }
  }, [payToken, receiveToken]);

  const fetchTokenPrice = useCallback(async (tokenAddress: string) => {
    try {
      const response = await fetch(`https://api.kittyswap.xyz/api/v1/priceToken?tokenAddress=${tokenAddress}&amountIn=1`);
      const data = await response.json();
      if (data && data.tokenValueInUSD) {
        setTokenPriceUSD(data.tokenValueInUSD);
      }
    } catch (error) {
      console.error('Failed to fetch token price', error);
      setTokenPriceUSD(null);
    }
  }, []);

  useEffect(() => {
    if (payToken && receiveToken) {
      fetchLiquidityData(payToken.address, receiveToken.address);
    }
  }, [payToken, receiveToken, fetchLiquidityData]);

  const updateAmounts = useCallback(async () => {
    if (!routerContract || !payToken || !receiveToken || !payAmount) {
      console.error('Missing required inputs for updateAmounts');
      return;
    }

    try {
      const path = [payToken.address, receiveToken.address];
      const amountInBigInt = BigInt(Math.floor(parseFloat(payAmount) * 1e8));
      const amountsOut = await routerContract.getAmountsOut(amountInBigInt, path);
      // @ts-ignore
      if (amountsOut?.properties?.amountsOut) {
        // @ts-ignore
        const amountOut = (Number(amountsOut.properties.amountsOut[1]) / 1e8).toString();
        setReceiveAmount(amountOut);
      } else {
        setReceiveAmount('0');
      }
    } catch (error) {
      console.error('Failed to fetch amounts out', error);
      setReceiveAmount('0');
    }
  }, [routerContract, payAmount, payToken, receiveToken]);

  useEffect(() => {
    updateAmounts();
  }, [payAmount, updateAmounts]);

  const handleOpenModal = (selection: 'pay' | 'receive') => {
    setCurrentSelection(selection);
    setIsModalOpen(true);
  };

  const handleAmountChange = useCallback(
    (amount: string, isPayToken: boolean) => {
      if (isPayToken) {
        setPayAmount(amount);
      } else {
        setReceiveAmount(amount);
      }
      updateAmounts();
    },
    [updateAmounts]
  );

  const handleSwitch = useCallback(() => {
    setPayToken(receiveToken);
    setReceiveToken(payToken);
    setPayAmount(receiveAmount);
    setReceiveAmount(payAmount);
    setPayTokenBalance(receiveTokenBalance);
    setReceiveTokenBalance(payTokenBalance);
  }, [payToken, receiveToken, payAmount, receiveAmount, payTokenBalance, receiveTokenBalance]);

  const handleSwap = useCallback(async () => {
    const amount = BigInt(Math.floor(parseFloat(payAmount) * 1e8));
    const minReceiveAmount = BigInt(Math.floor(parseFloat(receiveAmount) * 1e8));
    const isSuccessful = await executeApprovalAndSwap(amount, minReceiveAmount);
    if (!isSuccessful) {
      console.error('Swap failed.');
    }
  }, [payAmount, receiveAmount, executeApprovalAndSwap]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#100C1C] to-[#1A1528] flex items-start justify-center py-8 px-4">
      <div className="bg-[#181c2c] rounded-xl shadow-2xl p-6 w-full max-w-md">
        <TabInterface
          payToken={payToken}
          receiveToken={receiveToken}
          payAmount={payAmount}
          receiveAmount={receiveAmount}
          onPayAmountChange={(amount: string) => handleAmountChange(amount, true)}
          onReceiveAmountChange={(amount: string) => handleAmountChange(amount, false)}
          onPayTokenClick={() => handleOpenModal('pay')}
          onReceiveTokenClick={() => handleOpenModal('receive')}
          payTokenBalance={payTokenBalance}
          receiveTokenBalance={receiveTokenBalance}
          onSwap={handleSwitch}
          poolInfo={poolInfo}
          tokenPriceUSD={tokenPriceUSD}
          liquidityTokenA={liquidityTokenA}
          liquidityTokenB={liquidityTokenB}
          approvalSwapLoading={approvalSwapLoading}
        />
        <p className="text-gray-400 text-xs mt-3 text-center">
          Please make sure you understand the risks before trading.
        </p>
      </div>
      {isModalOpen && (
        <TokenModal
          tokens={tokensData}
          onSelect={handleTokenSelect}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Module;