import {
    FACTORY_ADDRESS_REGTEST,
    FACTORY_ADDRESS_FRACTAL,
    POOL_ADDRESS_REGTEST,
    POOL_ADDRESS_FRACTAL,
    WBTC_ADDRESS_REGTEST,
    WBTC_ADDRESS_FRACTAL,
    MOTO_ADDRESS_REGTEST,
    MOTO_ADDRESS_FRACTAL,
    ROUTER_ADDRESS_REGTEST,
    ROUTER_ADDRESS_FRACTAL
} from '@btc-vision/transaction';

export const addresses = {
    regtest: {
        factory: FACTORY_ADDRESS_REGTEST,
        pool: POOL_ADDRESS_REGTEST,
        wbtc: WBTC_ADDRESS_REGTEST,
        moto: MOTO_ADDRESS_REGTEST,
        router: ROUTER_ADDRESS_REGTEST,
    },
    fractal: {
        factory: FACTORY_ADDRESS_FRACTAL,
        pool: POOL_ADDRESS_FRACTAL,
        wbtc: WBTC_ADDRESS_FRACTAL,
        moto: MOTO_ADDRESS_FRACTAL,
        router: ROUTER_ADDRESS_FRACTAL,
    }
};
