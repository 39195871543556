import React, { useState } from 'react'
import SwapInterface from '../SwapInterface/SwapInterface'
import { ArrowLeftRight, TrendingUp, TrendingDown, ArrowUpDown, DollarSign, Droplet, BarChart3, ChevronDown, ChevronUp } from 'lucide-react'
import Gauge from '../Gauge/Gauge'

interface Token {
  address: string
  symbol: string
  image: string
}

interface TabInterfaceProps {
  payToken: Token | undefined
  receiveToken: Token | undefined
  payAmount: string
  receiveAmount: string
  onPayAmountChange: (amount: string) => void
  onReceiveAmountChange: (amount: string) => void
  onPayTokenClick: () => void
  onReceiveTokenClick: () => void
  payTokenBalance: number
  receiveTokenBalance: number
  onSwap: () => void
  poolInfo: any
  tokenPriceUSD: number | null
  liquidityTokenA: number
  liquidityTokenB: number
  approvalSwapLoading: boolean
}

export default function TabInterface({
  payToken,
  receiveToken,
  payAmount,
  receiveAmount,
  onPayAmountChange,
  onReceiveAmountChange,
  onPayTokenClick,
  onReceiveTokenClick,
  payTokenBalance,
  receiveTokenBalance,
  onSwap,
  poolInfo,
  tokenPriceUSD,
  liquidityTokenA,
  liquidityTokenB,
  approvalSwapLoading
}: TabInterfaceProps) {
  const [activeTab, setActiveTab] = useState("swap")
  const [isPoolInfoOpen, setIsPoolInfoOpen] = useState(false)

  const tabStyle = "flex-1 flex items-center justify-center px-4 py-3 rounded-t-lg transition-all duration-200 ease-in-out"
  const activeTabStyle = `${tabStyle} bg-[#2c2f36] text-white shadow-inner`
  const inactiveTabStyle = `${tabStyle} bg-[#1e2128] text-gray-400 hover:bg-[#2c2f36] hover:text-white`

  const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="w-full bg-[#1e2128] rounded-lg overflow-hidden shadow-lg">
      <div className="flex mb-0">
        <button
          onClick={() => setActiveTab("swap")}
          className={activeTab === "swap" ? activeTabStyle : inactiveTabStyle}
        >
          <ArrowLeftRight className="w-4 h-4 mr-2" />
          Swap
        </button>
        <button
          onClick={() => setActiveTab("long")}
          className={activeTab === "long" ? activeTabStyle : inactiveTabStyle}
        >
          <TrendingUp className="w-4 h-4 mr-2" />
          Long
        </button>
        <button
          onClick={() => setActiveTab("short")}
          className={activeTab === "short" ? activeTabStyle : inactiveTabStyle}
        >
          <TrendingDown className="w-4 h-4 mr-2" />
          Short
        </button>
      </div>
      <div className="bg-[#2c2f36] p-6 rounded-b-lg">
        {activeTab === "swap" && (
          <>
            <SwapInterface
              payToken={payToken}
              receiveToken={receiveToken}
              payAmount={payAmount}
              receiveAmount={receiveAmount}
              onPayAmountChange={onPayAmountChange}
              onReceiveAmountChange={onReceiveAmountChange}
              onPayTokenClick={onPayTokenClick}
              onReceiveTokenClick={onReceiveTokenClick}
              payTokenBalance={payTokenBalance}
              receiveTokenBalance={receiveTokenBalance}
              onSwap={onSwap}
            />
            <button
             // onClick={onSwap}
              disabled={approvalSwapLoading}
              className="w-full bg-[#f89d30] hover:bg-[#ffa94d] text-white font-bold py-4 px-6 rounded-lg mt-6 transition-colors duration-200 ease-in-out shadow-md hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {approvalSwapLoading ? "Processing..." : "Swap"}
            </button>
            <div className="mt-6">
              <button
                onClick={() => setIsPoolInfoOpen(!isPoolInfoOpen)}
                className="w-full bg-[#3a3f50] hover:bg-[#4a4f60] text-white font-semibold py-3 px-6 rounded-lg flex justify-between items-center transition-colors duration-200 ease-in-out"
              >
                <span className="flex items-center">
                  <ArrowUpDown className="w-5 h-5 mr-3 text-[#f89d30]" />
                  Pool Information
                </span>
                {isPoolInfoOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
              </button>
              {isPoolInfoOpen && (
                <div className="mt-4 bg-[#3a3f50] rounded-lg p-5 space-y-4 shadow-inner">
                  <p className="text-sm text-gray-300 font-medium">
                    {payToken?.symbol || 'TOKEN'} / {receiveToken?.symbol || 'TOKEN'}
                  </p>
                  <div className="bg-[#2c2f36] rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-400 flex items-center mb-2">
                      <DollarSign className="w-5 h-5 mr-2 text-[#f89d30]" />
                      Price
                    </h3>
                    <p className="text-lg font-bold text-white">{formatNumber(tokenPriceUSD || 0)}</p>
                  </div>
                  <div className="bg-[#2c2f36] rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-400 flex items-center mb-2">
                      <BarChart3 className="w-5 h-5 mr-2 text-[#f89d30]" />
                      Volume (24h)
                    </h3>
                    <p className="text-lg font-bold text-white">{formatNumber(0)}</p>
                  </div>
                  <div className="bg-[#2c2f36] rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-400 flex items-center mb-2">
                      <Droplet className="w-5 h-5 mr-2 text-[#f89d30]" />
                      Liquidity
                    </h3>
                    <p className="text-lg font-bold text-white mb-3">{formatNumber(poolInfo?.totalUSD || 0)}</p>
                    <div className="space-y-3">
                      <Gauge
                        value={liquidityTokenA}
                        color={payToken?.symbol === 'WBTC' ? '#f7931a' : '#802c9c'}
                        image={payToken?.image}
                      />
                      <Gauge
                        value={liquidityTokenB}
                        color={receiveToken?.symbol === 'WBTC' ? '#f7931a' : '#802c9c'}
                        image={receiveToken?.image}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === "long" && (
          <div className="text-center text-white">
            <h2 className="text-2xl font-bold mb-4">Long {payToken?.symbol}</h2>
            <p className="text-gray-400 mb-6">Long functionality coming soon.</p>
            <button
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-6 rounded-lg transition-colors duration-200 ease-in-out shadow-md hover:shadow-lg"
            >
              Open Position
            </button>
          </div>
        )}
        {activeTab === "short" && (
          <div className="text-center text-white">
            <h2 className="text-2xl font-bold mb-4">Short {payToken?.symbol}</h2>
            <p className="text-gray-400 mb-6">Short functionality coming soon.</p>
            <button
              className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-4 px-6 rounded-lg transition-colors duration-200 ease-in-out shadow-md hover:shadow-lg"
            >
              Open Short
            </button>
          </div>
        )}
      </div>
    </div>
  )
}