import React, { useState, useEffect, useCallback } from 'react'
import { useWallet } from '../../context/WalletContext'
import { ChevronDown, Clock, Wallet } from 'lucide-react'
import tokensData from '../../config/constants/tokens.json'

interface Token {
  address: string
  name: string
  symbol: string
  image: string
  balance?: string
}

export default function StakeInterface() {
  const { address } = useWallet()
  const [selectedToken, setSelectedToken] = useState<Token | null>(null)
  const [amount, setAmount] = useState<string>('0')
  const [duration, setDuration] = useState<number>(26)
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)
  const [selectedPercentage, setSelectedPercentage] = useState<number | null>(null)
  const [currentBlock, setCurrentBlock] = useState<number>(0)

  useEffect(() => {
    setCurrentBlock(Math.floor(Math.random() * 1000000))
  }, [])

  const handleTokenSelect = useCallback((token: Token) => {
    setSelectedToken(token)
    setIsTokenModalOpen(false)
  }, [])

  const handleAmountChange = useCallback((value: string) => {
    setAmount(value)
    setSelectedPercentage(null)
  }, [])

  const handlePercentageClick = useCallback((percentage: number) => {
    if (selectedToken && selectedToken.balance) {
      const balance = parseFloat(selectedToken.balance)
      setAmount((balance * percentage / 100).toFixed(4))
      setSelectedPercentage(percentage)
    }
  }, [selectedToken])

  const handleDurationChange = useCallback((weeks: number) => {
    setDuration(weeks)
  }, [])

  const handleMaxClick = useCallback(() => {
    if (selectedToken && selectedToken.balance) {
      setAmount(selectedToken.balance)
    }
  }, [selectedToken])

  const formatBalance = (balance: number) => {
    return balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 4 })
  }

  const noSpinnerStyles = `
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }`

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#100C1C] to-[#1A1528] flex items-start justify-center py-6 px-4">
      <style>{noSpinnerStyles}</style>
      <div className="bg-[#181c2c] rounded-xl shadow-2xl p-4 w-full max-w-2xl">
        <h1 className="text-xl font-bold text-white mb-3 text-center">Lock Token to get veToken</h1>
        
        <div className="bg-[#2c2f36] rounded-lg p-4 mb-4 border border-[#f89d30]">
          <div className="mb-2">
            <label htmlFor="stakeAmount" className="block text-sm text-gray-400 mb-1 flex items-center">
              <Wallet className="w-4 h-4 mr-2" />
              Stake Token
            </label>
            <div className="relative mb-1">
              <input
                id="stakeAmount"
                type="number"
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                className="w-full bg-[#1a1d26] text-white text-lg py-2 pl-3 pr-24 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f89d30]"
                placeholder="0"
              />
              <button
                onClick={() => setIsTokenModalOpen(true)}
                className="absolute right-1 top-1 bottom-1 flex items-center bg-[#3a3f50] hover:bg-[#4a4f60] text-white px-3 py-1 rounded-lg transition-colors"
              >
                {selectedToken ? (
                  <>
                    <img src={selectedToken.image} alt={selectedToken.symbol} className="w-5 h-5 mr-1 rounded-full" />
                    <span className="mr-1">{selectedToken.symbol}</span>
                  </>
                ) : (
                  <span>Select</span>
                )}
                <ChevronDown className="h-4 w-4" />
              </button>
            </div>
            {selectedToken && (
              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-400">
                  Balance: {selectedToken.balance} {selectedToken.symbol}
                </span>
                <button
                  onClick={handleMaxClick}
                  className="text-[#f89d30] hover:text-[#ffa94d] font-medium transition-colors"
                >
                  Max
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 gap-2 mt-2">
            {[25, 50, 75, 100].map((percentage) => (
              <button
                key={percentage}
                onClick={() => handlePercentageClick(percentage)}
                className={`text-white px-2 py-1 rounded-lg text-sm transition-colors ${
                  selectedPercentage === percentage ? 'bg-[#f89d30]' : 'bg-[#3a3f50] hover:bg-[#4a4f60]'
                }`}
              >
                {percentage === 100 ? 'Max' : `${percentage}%`}
              </button>
            ))}
          </div>
        </div>

        <div className="bg-[#2c2f36] rounded-lg p-4 mb-4 border border-[#f89d30]">
          <label className="text-sm text-gray-400 mb-2 block flex items-center">
            <Clock className="w-4 h-4 mr-2" />
            Add Duration
          </label>
          <div className="bg-[#1a1d26] text-white py-2 px-3 rounded-lg flex flex-col justify-between h-[72px]">
            <div className="flex justify-between items-center">
              <span className="text-2xl font-bold">{duration}</span>
              <span>Weeks</span>
            </div>
            <div className="text-sm text-gray-400">
              Unlock at block: {currentBlock + duration * 7 * 24 * 60 * 5}
            </div>
          </div>
          <div className="grid grid-cols-5 gap-2 mt-2">
            {[1, 4, 26, 52, 208].map((weeks) => (
              <button
                key={weeks}
                onClick={() => handleDurationChange(weeks)}
                className={`text-white px-2 py-1 rounded-lg text-sm transition-colors ${
                  duration === weeks ? 'bg-[#f89d30]' : 'bg-[#3a3f50] hover:bg-[#4a4f60]'
                }`}
              >
                {weeks === 1 ? '1W' : weeks === 4 ? '1M' : weeks === 26 ? '6M' : weeks === 52 ? '1Y' : '4Y'}
              </button>
            ))}
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#f89d30] to-[#ffa94d] rounded-lg p-4 mb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img src="/images/velogo.png?height=40&width=40" alt="veToken" className="w-10 h-10 mr-2 rounded-full" />
              <span className="text-xl font-bold text-white">My veKITTY</span>
            </div>
            <span className="text-2xl font-bold text-white">0</span>
          </div>
        </div>

        <div className="bg-[#2c2f36] rounded-lg p-4 mb-4 border border-[#f89d30]">
          <div className="space-y-2 text-white">
            <div className="flex justify-between">
              <span>Total APR</span>
              <span className="text-[#f89d30]">25.69%</span>
            </div>
            <div className="flex justify-between">
              <span>veKitty Pool APR</span>
              <span className="text-[#f89d30]">3.01%</span>
            </div>
            <div className="flex justify-between">
              <span>Revenue Sharing APR</span>
              <span className="text-[#f89d30]">2.68%</span>
            </div>
          </div>
        </div>

        <button className="w-full bg-[#f89d30] hover:bg-[#ffa94d] text-white font-bold py-2 px-4 rounded-lg transition-colors">
          Lock
        </button>
      </div>

      {isTokenModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#2c2f36] rounded-lg p-4 max-w-md w-full">
            <h2 className="text-xl font-bold text-white mb-4">Select Token</h2>
            {tokensData.map((token) => (
              <button
                key={token.address}
                className="w-full px-4 py-2 text-left hover:bg-[#3A3D4E] flex items-center justify-between"
                onClick={() => handleTokenSelect({ ...token, balance: (Math.random() * 1000).toFixed(4) })}
              >
                <div className="flex items-center">
                  <img src={token.image} alt={token.name} className="w-6 h-6 mr-2 rounded-full" />
                  <span className="text-white">{token.symbol}</span>
                </div>
                <span className="text-gray-400">
                  Balance: {formatBalance(parseFloat((Math.random() * 1000).toFixed(4)))}
                </span>
              </button>
            ))}
            <button
              className="mt-4 w-full bg-[#f89d30] hover:bg-[#ffa94d] text-white font-bold py-2 px-4 rounded-lg transition-colors"
              onClick={() => setIsTokenModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}