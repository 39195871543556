import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react'

interface Token {
  address: string;
  symbol: string;
  image: string;
}

interface TokenInputProps {
  label: string;
  token?: Token;
  balance: number;
  amount: string;
  onChangeAmount: (amount: string) => void;
  onClick: () => void;
}

const TokenInput: React.FC<TokenInputProps> = ({ label, token, balance, amount, onChangeAmount, onClick }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Permet uniquement les chiffres, un point ou une virgule
    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
      onChangeAmount(value);
    }
  }

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (amount === '0') {
      onChangeAmount('')
    }
    e.target.placeholder = ''
  }

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    if (e.target.value === '') {
      onChangeAmount('0')
    }
    e.target.placeholder = '0.0'
  }

  const handleBalanceClick = () => {
    onChangeAmount(balance.toFixed(4))
  }

  const displayAmount = isFocused ? amount : amount === '0' ? '' : amount

  return (
    <div className="bg-[#181c2c] rounded-lg border border-[#f89d30] p-4 mb-4">
      <label className="block text-sm font-medium text-gray-400 mb-2">{label}</label>
      <div className="flex items-center bg-[#2c2f36] rounded-lg overflow-hidden">
        <input
          type="text"
          placeholder="0.0"
          value={displayAmount}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className={`w-full bg-transparent text-white text-lg py-3 px-4 focus:outline-none ${
            amount === '0' && !isFocused ? 'text-gray-500' : ''
          }`}
        />
        <button
          onClick={onClick}
          className="flex items-center bg-[#3a3f50] hover:bg-[#4a4f60] transition-colors px-4 py-3 rounded-r-lg"
        >
          {token ? (
            <>
              <img src={token.image} alt={token.symbol} className="w-6 h-6 rounded-full mr-2" />
              <span className="text-white font-medium mr-1">{token.symbol}</span>
            </>
          ) : (
            <span className="text-white font-medium">Select</span>
          )}
          <ChevronDown className="h-4 w-4 text-gray-400 ml-2" />
        </button>
      </div>
      {token && (
        <div className="flex justify-between items-center mt-2 text-sm">
          <span className="text-gray-400">Balance: {balance.toFixed(4)}</span>
          <button
            onClick={handleBalanceClick}
            className="text-[#f89d30] hover:text-[#ffa94d] transition-colors font-medium"
          >
            Max
          </button>
        </div>
      )}
    </div>
  )
};

export default TokenInput;