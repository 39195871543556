import React from 'react'
import TokenInput from '../Input/TokenInput'
import { ArrowDownUp } from 'lucide-react'

interface Token {
  address: string
  symbol: string
  image: string
}

interface SwapInterfaceProps {
  payToken: Token | undefined
  receiveToken: Token | undefined
  payAmount: string
  receiveAmount: string
  onPayAmountChange: (amount: string) => void
  onReceiveAmountChange: (amount: string) => void
  onPayTokenClick: () => void
  onReceiveTokenClick: () => void
  payTokenBalance: number
  receiveTokenBalance: number
  onSwap: () => void
}

export default function SwapInterface({
  payToken,
  receiveToken,
  payAmount,
  receiveAmount,
  onPayAmountChange,
  onReceiveAmountChange,
  onPayTokenClick,
  onReceiveTokenClick,
  payTokenBalance,
  receiveTokenBalance,
  onSwap
}: SwapInterfaceProps) {
  const handleSwap = () => {
    // Appeler la fonction onSwap pour échanger les tokens
    onSwap()

    // Échanger les montants
    const tempAmount = payAmount
    onPayAmountChange(receiveAmount)
    onReceiveAmountChange(tempAmount)
  }

  const noSpinnerStyles = `
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }`

  return (
    <div className="max-w-md mx-auto">
      <style>{noSpinnerStyles}</style>
      <TokenInput
        label="From"
        token={payToken}
        balance={payTokenBalance}
        amount={payAmount}
        onChangeAmount={onPayAmountChange}
        onClick={onPayTokenClick}
      />
      <div className="flex justify-center my-4 relative z-10">
        <button
          onClick={handleSwap}
          className="bg-[#f89d30] hover:bg-[#ffa94d] text-white rounded-full p-2 shadow-lg transition-colors focus:outline-none focus:ring-2 focus:ring-[#f89d30] focus:ring-opacity-50"
          aria-label="Swap tokens"
        >
          <ArrowDownUp className="h-6 w-6" />
        </button>
      </div>
      <TokenInput
        label="To"
        token={receiveToken}
        balance={receiveTokenBalance}
        amount={receiveAmount}
        onChangeAmount={onReceiveAmountChange}
        onClick={onReceiveTokenClick}
      />
    </div>
  )
}