import React, { createContext, useState, useContext, ReactNode } from 'react';
import { JSONRpcProvider, getContract } from 'opnet';

interface WalletContextProps {
  address: string | null;
  balance: number | null;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => void;
  fetchTokenBalance: (contractAddress: string, userAddress: string, abi: any) => Promise<number | null>;
  provider: JSONRpcProvider;
}

const WalletContext = createContext<WalletContextProps | undefined>(undefined);

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export const WalletProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [address, setAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<number | null>(null);

  const provider = new JSONRpcProvider(process.env.REACT_APP_RPC_URL!);

  const fetchBalance = async (address: string) => {
    try {
      const balanceResponse = await provider.getBalance(address);
      const balanceInBTC = Number(balanceResponse) / 1e8;
      setBalance(balanceInBTC);
    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  const fetchTokenBalance = async (contractAddress: string, userAddress: string, abi: any): Promise<number | null> => {
    try {
      const contract = getContract<any>(contractAddress, abi, provider);
      const balanceExample = await contract.balanceOf(userAddress);

      if ('error' in balanceExample) {
        console.error('Error in fetching token balance:', balanceExample.error);
        return null;
      }

      return Number(balanceExample.decoded) / 1e8;
    } catch (error) {
      console.error('Failed to fetch token balance:', error);
      return null;
    }
  };

  const connectWallet = async () => {
    if (typeof window.unisat === 'undefined') {
      alert('UniSat Wallet is not installed!');
      return;
    }

    try {
      const accounts = await window.unisat.requestAccounts();
      const walletAddress = accounts[0];
      setAddress(walletAddress);
      await fetchBalance(walletAddress);
    } catch (e) {
      console.error('Failed to connect wallet:', e);
    }
  };

  const disconnectWallet = () => {
    setAddress(null);
    setBalance(null);
  };

  return (
    <WalletContext.Provider value={{ address, balance, connectWallet, disconnectWallet, fetchTokenBalance, provider }}>
      {children}
    </WalletContext.Provider>
  );
};
