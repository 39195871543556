import React from 'react'

interface GaugeProps {
  value: number
  color: string
  image?: string
}

export default function Gauge({ value, color, image }: GaugeProps) {
  const maxValue = Math.pow(10, Math.ceil(Math.log10(value)))
  const percentage = (value / maxValue) * 100

  const formatNumber = (value: number) => {
    if (value >= 1e12) return `${(value / 1e12).toFixed(1)}T`
    if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`
    return value.toFixed(2)
  }

  return (
    <div className="w-full">
      <div className="h-4 bg-gray-700 rounded-full overflow-hidden">
        <div
          className="h-full transition-all duration-500 ease-out"
          style={{ width: `${percentage}%`, backgroundColor: color }}
        />
      </div>
      <div className="flex justify-between items-center mt-1">
        <span className="text-sm font-medium text-gray-300">{formatNumber(value)}</span>
        {image && <img src={image} alt="Token logo" className="w-4 h-4 rounded-full" />}
      </div>
    </div>
  )
}