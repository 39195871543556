import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './views/Home/Home';
import Trade from './views/Trade/Trade';
import Stake from './views/Stake/Stake';
import Prediction from './views/Prediction/Prediction';
import Footer from './components/Footer/Footer';
import { WalletProvider } from './context/WalletContext';
import './App.css';
import { Buffer } from 'buffer';

// Assurez-vous que Buffer est défini globalement
if (!window.Buffer) {
    window.Buffer = Buffer;
}

const App: React.FC = () => {
  return (
    <Router>
      <WalletProvider>
        <div className="app-container">
          <Header />
          <div className="content-wrap">
            <Routes>
            <Route path="/home" element={<Home />} />
              <Route path="/swap" element={<Trade />} />
              <Route path="/stake" element={<Stake />} />
              <Route path="/prediction" element={<Prediction />} />
              <Route path="/" element={<Navigate to="/home" />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </WalletProvider>
    </Router>
  );
};

export default App;
