import { useState, useCallback } from 'react';
import { networks } from 'bitcoinjs-lib';
import { OPNetLimitedProvider, FetchUTXOParamsMultiAddress, TransactionFactory, UnisatSigner, UTXO } from '@btc-vision/transaction';
import { useWallet } from '../context/WalletContext';
import { useRouterContract } from './useRouterContract';

export const useSwap = (payToken: string, receiveToken: string) => {
    const { address, provider } = useWallet();
    const routerContract = useRouterContract('regtest', provider);
    const [loading, setLoading] = useState<boolean>(false);

    const executeSwap = useCallback(async (payAmount: number) => {
        if (!provider || !routerContract || !payAmount) {
            console.error('Missing required inputs for swap');
            return;
        }

        setLoading(true);

        try {
            // 1. Fetch UTXOs
            const utxoManager = new OPNetLimitedProvider(process.env.REACT_APP_RPC_URL!);
            const utxoSetting: FetchUTXOParamsMultiAddress = {
                addresses: [address!],
                minAmount: BigInt(10000),
                requestedAmount: BigInt(payAmount * 1e8),
            };
            const utxos = await utxoManager.fetchUTXOMultiAddr(utxoSetting);
            console.log('UTXOs:', utxos);
            if (!utxos || !utxos.length) {
                throw new Error('No UTXOs found');
            }

            // 2. Calculate receiveAmount using getAmountsOut
            const path = [payToken, receiveToken];
            const amountsOut = await routerContract.getAmountsOut(BigInt(payAmount * 1e8), path);
// @ts-ignore
            if (!amountsOut || !amountsOut.properties?.amountsOut) {
                console.error('Failed to fetch amounts out');
                return;
            }
// @ts-ignore
            const receiveAmount = Number(amountsOut.properties.amountsOut[1]) / 1e8;

            // 3. Create and sign transaction for the swap
            const factory = new TransactionFactory();
            const keypair = new UnisatSigner();
            await keypair.init();

            const calldata = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                BigInt(payAmount * 1e8),
                BigInt(Math.floor(receiveAmount * 0.95 * 1e8)),
                [payToken, receiveToken],
                address!,
                BigInt(Date.now() + 60 * 20)
            );

            const interactionParameters = {
                from: keypair.p2tr,
                to: routerContract.address.toString(),
                utxos: utxos,
                network: networks.regtest,
                feeRate: 450,
                priorityFee: BigInt(10000),
                calldata: Buffer.isBuffer(calldata) ? calldata : Buffer.from(calldata, 'hex'),
                signer: keypair,
            };

            console.log('Interaction Parameters:', interactionParameters);
            const signedTx = await factory.signInteraction(interactionParameters);
            console.log('Signed Transaction:', signedTx);

            if (!signedTx) {
                throw new Error('Failed to sign the transaction');
            }

            // 4. Broadcast transaction
            const broadcastTxA = await provider.sendRawTransaction(signedTx[0], false);
            const broadcastTxB = await provider.sendRawTransaction(signedTx[1], false);

            console.log('BroadcastTxA:', broadcastTxA);
            console.log('BroadcastTxB:', broadcastTxB);

            if (!broadcastTxA.result || !broadcastTxB.result) {
                throw new Error('Transaction broadcast failed');
            }

            console.log('Transaction broadcasted successfully:', broadcastTxA.result);
        } catch (error) {
            console.error('Swap failed:', error instanceof Error ? error.message : error);
        } finally {
            setLoading(false);
        }
    }, [routerContract, provider, address, payToken, receiveToken]);

    return { executeSwap, loading };
};
