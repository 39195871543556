import React, { useState, useEffect } from 'react'
import { OP_20_ABI } from 'opnet' 
import { JSONRpcProvider, getContract } from 'opnet'
import { Search, X } from 'lucide-react'

interface Token {
  address: string
  name: string
  symbol: string
  image: string
}

interface TokenModalProps {
  tokens: Token[]
  onSelect: (token: Token) => void
  onClose: () => void
}

const TokenModal: React.FC<TokenModalProps> = ({ tokens, onSelect, onClose }) => {
  const [search, setSearch] = useState('')
  const [filteredTokens, setFilteredTokens] = useState<Token[]>(tokens)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenDetails = async () => {
      setLoading(false)
      setError(null)

      if (search.trim() === '') {
        setFilteredTokens(tokens)
        return
      }

      setFilteredTokens([])
      setLoading(true)

      try {
        const provider = new JSONRpcProvider(process.env.REACT_APP_RPC_URL!)
        const contract = getContract<any>(search.trim(), OP_20_ABI, provider)

        const symbolResponse = await contract.symbol()
        const nameResponse = await contract.name()

        const symbol = symbolResponse.decoded || symbolResponse.result || 'UNKNOWN'
        const name = nameResponse.decoded || nameResponse.result || 'UNKNOWN'

        const newToken: Token = {
          address: search.trim(),
          name,
          symbol,
          image: './images/token.png?height=40&width=40',
        }

        setFilteredTokens([newToken])
      } catch (err) {
        console.error('Failed to fetch token details:', err)
        setError('Failed to fetch token details. Please check the address and try again.')
      } finally {
        setLoading(false)
      }
    }

    const debounce = setTimeout(() => {
      fetchTokenDetails()
    }, 500)

    return () => clearTimeout(debounce)
  }, [search, tokens])

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#181c2c] rounded-lg max-w-md w-full shadow-xl" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-white">Select a token</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white transition-colors">
            <X className="h-6 w-6" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search name or paste address"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full bg-[#2c2f36] text-white placeholder-gray-400 rounded-lg py-2 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-[#f89d30]"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          {loading && (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#f89d30]"></div>
            </div>
          )}
          {error && (
            <div className="text-red-500 text-center mt-4">{error}</div>
          )}
          <div className="mt-4 max-h-60 overflow-y-auto">
            {filteredTokens.map((token) => (
              <button
                key={token.address}
                onClick={() => onSelect(token)}
                className="w-full flex items-center p-2 hover:bg-[#2c2f36] rounded-lg transition-colors"
              >
                <img src={token.image} alt={token.symbol} className="w-8 h-8 rounded-full mr-3" />
                <div className="flex flex-col items-start">
                  <span className="text-white font-medium">{token.symbol}</span>
                  <span className="text-gray-400 text-sm">{token.name}</span>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default TokenModal;


