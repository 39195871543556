import React, { useEffect, useState } from 'react';
import { Coins, BarChart3, Users } from 'lucide-react';

const Home: React.FC = () => {
  const [tradingVolume, setTradingVolume] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [numberOfPairs, setNumberOfPairs] = useState(0);

  useEffect(() => {
    const incrementValue = (start: number, end: number, setValue: React.Dispatch<React.SetStateAction<number>>) => {
      const duration = 2000; // Duration of the animation in milliseconds
      const increment = end / (duration / 20); // Increment value per step

      let currentValue = start;
      const timer = setInterval(() => {
        currentValue += increment;
        if (currentValue >= end) {
          setValue(end);
          clearInterval(timer);
        } else {
          setValue(Math.floor(currentValue));
        }
      }, 20);
    };

    incrementValue(0, 100000000, setTradingVolume);
    incrementValue(0, 50000, setTotalUsers);
    incrementValue(0, 20, setNumberOfPairs); // Adjust this number as needed
  }, []);

  const formatNumber = (number: number) => {
    return number.toLocaleString('en-US'); // This will add commas to the number
  };

  return (
    <div className="min-h-screen bg-[#100C1C] text-white font-sans">
      <div className="container mx-auto px-4 py-16 flex flex-col items-center">
        <img src="./images/logo.png?height=120&width=120" alt="Kitty Logo" className="w-32 h-32 mb-8" />
        <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center bg-gradient-to-r from-[#F89D30] to-orange-400 text-transparent bg-clip-text">
          Decentralized Perpetual Exchange
        </h1>
        <p className="text-xl md:text-2xl mb-8 text-center text-gray-300">
          Trade BTC and OP20 tokens with high leverage, directly from your wallet on OP_NET
        </p>
        <div className="flex flex-col sm:flex-row gap-4 mb-16">
        <button 
            className="px-6 py-3 bg-[#F89D30] hover:bg-orange-600 text-white rounded-lg transition-all duration-300 transform hover:scale-105 flex items-center justify-center"
            onClick={() => window.location.href = 'https://magiceden.io/runes/KITTY%E2%80%A2ON%E2%80%A2OPNET'}
          >
            Buy Now 
            
          </button>
          <button 
            className="px-6 py-3 bg-[#F89D30] hover:bg-orange-600 text-white rounded-lg transition-all duration-300 transform hover:scale-105 flex items-center justify-center"
            onClick={() => window.location.href = '/swap'}
          >
            Trade 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
          <button 
            className="px-6 py-3 border-2 border-[#F89D30] text-[#F89D30] hover:bg-[#F89D30] hover:text-white rounded-lg transition-all duration-300 transform hover:scale-105"
            onClick={() => window.open('https://docs.kittyswap.xyz', '_blank')}
          >
            View Docs
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-4xl">
          <InfoCard
            icon={<BarChart3 className="h-8 w-8 text-[#F89D30]" />}
            title="Total Trading Volume"
            value={`$${formatNumber(tradingVolume)}`}
          />
          <InfoCard
            icon={<Users className="h-8 w-8 text-[#F89D30]" />}
            title="Total Users"
            value={formatNumber(totalUsers)}
          />
          <InfoCard
            icon={<Coins className="h-8 w-8 text-[#F89D30]" />}
            title="Trading Pairs"
            value={formatNumber(numberOfPairs)}
          />
        </div>
      </div>
    </div>
  )
}

function InfoCard({ icon, title, value }: { icon: React.ReactNode; title: string; value: string }) {
  return (
    <div className="bg-[#1A1528] border border-[#F89D30] rounded-lg overflow-hidden group hover:shadow-lg hover:shadow-[#F89D30]/20 transition-all duration-300 transform hover:scale-105">
      <div className="flex flex-col items-center p-6 relative">
        <div className="absolute inset-0 bg-gradient-to-br from-[#F89D30]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        {icon}
        <h3 className="text-lg font-semibold mt-4 mb-2 text-gray-200">{title}</h3>
        <p className="text-2xl font-bold text-[#F89D30]">{value}</p>
      </div>
    </div>
  )
}

export default Home;